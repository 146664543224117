@import "https://d1qdhko150dv52.cloudfront.net/stylesheets/@{env-font-family}.css";
@import '../fonts/stylesheet.css';

/**** General Typrography ****/
.normal-regular {
  font-family: @regular-font-family;
  font-style: normal;
}

.black-regular {
  font-family: @black-font-family;
  font-style: normal;
}

.light-regular {
  font-family: @light-font-family;
  font-style: normal;
}

.bold-regular {
  font-family: @bold-font-family;
  font-style: normal;
}
/**** General Typrography ends ****/

/* Heading with AkkuratLLWeb-Regular (Akkuralat LL as shown in Figma) */
.heading-01-regular {
  .normal-regular;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.heading-01-regular-12px-700-weight {
  .normal-regular;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  height: 17px;
  color: @soft-black;
}

.heading-02-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 19px;
  line-height: 22.8px;
  letter-spacing: 0.25px;
}

.heading-03-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
}

.heading-04-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.heading-05-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.heading-06-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.heading-07-light {
  .light-regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.5px;
}

.heading-07-bold {
  .bold-regular;
  font-weight: 700;
  font-size: 14px;
  line-height: 19.2px;
  opacity: 0.6;
}
/* Heading with AkkuratLLWeb-Regular (Akkuralat LL as shown in Figma) ends */

/* Heading with AkkuratLLWeb-Black starts */
.heading-02-black {
  .black-regular;
  font-weight: 900;
  font-size: 28px;
  line-height: 33.6px;
}

.heading-03-black {
  .black-regular;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
}

.heading-04-black {
  .black-regular;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
}

/* Heading with AkkuratLLWeb-Black ends */

/* Paragraph with AkkuratLLWeb-Regular starts */
.paragraph-01-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
}

.paragraph-01-light {
  .light-regular;
  font-weight: 300;
  font-size: 14px;
  line-height: 16.8px;
}

.paragraph-01-regular-soft-black {
  .paragraph-01-regular;
  padding: 1rem 1rem 1rem 0;
  color: @soft-black-75-opacity;
}

.paragraph-02-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 15.6px;
  color: @soft-black;
}

.paragraph-02-bold {
  .normal-regular;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 15.6px;
  color: @soft-black;
}

.paragraph-03-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.paragraph-04-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 11px;
  line-height: 15.95px;
}

.paragraph-04-light {
  .light-regular;
  font-weight: 300;
  font-size: 11px;
  line-height: 145%;
}

.paragraph-01-bold {
  .bold-regular;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  color: @soft-black;
}

.paragraph-02-bold {
  .normal-regular;
  font-weight: 700;
  font-size: 13px;
  line-height: normal;
  color: @soft-black;
}

.paragraph-03-bold {
  .normal-regular;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: @soft-black;
}

.paragraph-04-bold {
  .bold-regular;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.2px;
}

/* Paragraph with AkkuratLLWeb-Regular ends */

/* Paragraph with AkkuratLLWeb-Bold starts */

.heading-06-bold {
  .bold-regular;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  opacity: 0.6;
}

.heading-06-bold-normal-opacity {
  .heading-06-bold;
  opacity: 1;
}

.heading-06-regular-td {
  .paragraph-03-regular;
  color: @soft-black;
}

.heading-06-regular-table {
  .heading-06-regular;
  font-size: 14px;
}

.heading-06-bold-table, .heading-06-bold-table-td {
  .heading-06-bold;
  opacity: 1;
  color: @soft-black;
  font-size: 14px;
}
/* Paragraph with AkkuratLLWeb-Bold ends */

.regular-cap {
  .normal-regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.regular-cap-2 {
  .normal-regular;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.body-01-regular {
  .normal-regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: @soft-black;
}

html {
  /* 62.5% of 16px browser font size is 10px */
  font-size: 62.5% !important;
}

body {
  font-size: 1.2rem !important; /* 12px */
}

body,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  -webkit-font-feature-settings: unset !important;
  font-feature-settings: unset !important;
  font-variant: normal !important;
}

.desktop .odometer-container {
  top: 60px;
  left: 30px;
}
.laptop .odometer-container {
  top: 40px;
  left: 20px;
}

.section-header {
  .normal-regular;
  color: @soft-black;
  padding-top: 15px;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
}

.section-sub-header {
  .heading-02-regular;
  color: @soft-black;
}

.hover-underline:hover {
  text-decoration: underline;
}

.ant-menu-item, .ant-pagination-item, .ant-menu-inline-collapsed-tooltip, .ant-dropdown-menu-title-content {
  a {
    text-decoration: none !important;
  }
}

a {
  text-decoration: underline;
  color: @soft-black;
  > span {
    text-decoration: underline;
    color: @soft-black !important;
    
    &.ant-badge {
      text-decoration: none;
    }
  }
}

a:hover {
  text-decoration: underline;
  color: @soft-black;
}
